a {
    position: relative;
}

a::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

a:hover::before,
a:focus::before {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}