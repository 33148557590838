.bm-item {
    display: inline-block;

    text-decoration: none;
    margin-bottom: 10px;
    color: white;
}

.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 5%;
    top: 30%;
}

.bm-burger-bars {
    background: #122941;
}

.bm-cross-button {
    height: 30px !important;
    width: 30px !important;
    right: 35px !important;
}

.bm-cross {
    background: #FBFBFC;
    height: 30px !important;
}

.bm-menu-wrap {
    top: 0;
}

.bm-menu {
    background: #122941;
    padding: 80px 1.5em 0;
    font-size: 1.15em;
    position: absolute;
    width: 100%;
}
