@font-face {
    font-family: 'BlackerDisplay';
    src: url('./BlackerDisplay-Heavy.eot');
    src: url('./BlackerDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./BlackerDisplay-Heavy.woff') format('woff'),
    url('./BlackerDisplay-Heavy.ttf') format('truetype'),
    url('./BlackerDisplay-Heavy.otf') format('opentype');
    font-style: normal;
    font-weight: 900;
}
